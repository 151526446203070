// Queries and mutations unique to the Dashboard module
import { gql } from 'apollo-boost';

// Dashboard data
export const MAIN_DASHBOARD_QUERY = gql`
query currentStudent {
    currentStudent {
        id
        name
        boomerAvatarUrl
        updatedAt
        school {
            id
            name
        }
        entries {
            id
            text
            submittedAt
        }
        books {
            id
            currentChapter
            isSingleChapter
            title
            completedAt
            status
            chapters {
                id
                orderid
                title
                status
                studentEntries {
                  id
                  text
                }
            }
            bookTemplate {
                id
                name
                coverUrl
            }
            classPack {
                id
                isWritingActivity
            }
        }
    }
}
`;


export const GET_STORIES = gql`
query stories {
    stories {
        id
        content
        title
    }
}
`;

export const CONFIRM_STORY = gql`
mutation confirmStory($id: ID!) {
    stories(id: $id) {
        errors 
        story {
            id
            title
        }
    }
}
`;