import React from 'react';
import { Link } from '@reach/router';
import cx from 'classnames';
import moment from 'moment';

import noCover from '../../../images/no-cover.png';
import taskCover from '../../../images/task.png';

import styles from '../Dashboard.module.scss';
import { BOOK_STATUS, CHAPTER_STATUS } from '@axeedge/go-shared-utils';

const Book = ({ book }) => {
    const getSubtitle = () => {
        if (book.classPack?.isWritingActivity && book.status >= BOOK_STATUS.completed) {
            return moment(book.completedAt).format('MMMM YYYY');
        } else {
            if (book.status >= BOOK_STATUS.completed) {
                return 'Published!'
            }
            if (book.chapters[book.currentChapter]?.status === CHAPTER_STATUS.writing) {
                return 'Write Now!';
            }
            if (book.chapters[book.currentChapter]?.status === CHAPTER_STATUS.writing_closed || book.chapters[book.currentChapter]?.status === CHAPTER_STATUS.voting_ready) {
                return 'Writing has ended!';
            }
            if (book.chapters[book.currentChapter]?.status === CHAPTER_STATUS.voting) {
                return 'Vote Now!'
            }
            if (book.chapters[book.currentChapter]?.status === CHAPTER_STATUS.voting_closed) {
                return 'Voting has ended!';
            }
            return null;
        }

    }

    return (
        <Link key={`book-${book.id}`} to={`book/${book.id}`} className={styles.dashboardBookCard}>
            <div className={styles.dashboardBookCardContent}>
                <h2 className={styles.dashboardBookCardTitle}>{book.title || book.bookTemplate.name}</h2>
                <p className={styles.dashboardBookCardSubTitle}>{getSubtitle()}</p>
            </div>
            <img
                className={cx(styles.dashboardBookCardImg, { [styles.dashboardBookCardImgBook]: !book.classPack?.isWritingActivity })}
                src={book.bookTemplate.coverUrl || (book.classPack?.isWritingActivity ? taskCover : noCover)} 

                alt={book.title || book.bookTemplate.name}
            />
        </Link>
    );
}

export default Book;