import React, { Fragment } from 'react';
import cx from 'classnames';
import { Book, Edit3, Star, FileText } from 'react-feather';
import { CHAPTER_STATUS } from '@axeedge/go-shared-utils';

const ChapterLinks = ({ closeMenu, book, selectedChapter, setSelectedChapter }) => {

    return (
        <Fragment>
            <ul>
                {
                    !book.classPack?.isWritingActivity && book.chapters.map((chapter, i) => (
                        <li key={`link-${chapter.id}`}>
                            <button
                                onClick={() => {
                                    closeMenu();
                                    setSelectedChapter(chapter.orderid)
                                }}
                                className={cx('bookmenu__link', { 'bookmenu__link--active': selectedChapter === chapter.orderid })}
                            >
                                {i + 1}
                            </button>
                        </li>
                    ))
                }
                {
                    book.classPack?.isWritingActivity ? (
                        <li>
                            <button
                                onClick={() => {
                                    closeMenu();
                                    setSelectedChapter(0)
                                }}
                                className={cx('bookmenu__link', { 'bookmenu__link--active': selectedChapter === 0 })}
                            >
                                {book.chapters[0].status < CHAPTER_STATUS.voting ? <Edit3 /> : book.chapters[0].status > CHAPTER_STATUS.voting_closed ? <FileText /> : <Star />}
                            </button>
                        </li>
                    )
                        :
                        <li>
                            <button
                                onClick={() => {
                                    closeMenu();
                                    setSelectedChapter(-1)
                                }}
                                className={cx('bookmenu__link', { 'bookmenu__link--active': selectedChapter === -1 })}
                            >
                                <Book />
                            </button>
                        </li>
                }
            </ul>
        </Fragment>
    )
}

export default ChapterLinks;
